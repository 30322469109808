import { keepPreviousData } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { createQuery } from "@/lib/utils/tanstack-query";
import { RBACParams } from "@/types/ApiParams";

type ListFilterState =
  | {
      page?: number;
      search?: string;
    }
  | undefined;

const RBAC = "RBAC";
const createRBACQuery = createQuery(RBAC);

export const rbacQueries = {
  permissions: createRBACQuery(fetchApiClient.rbac.listRbacPermissions),
  permissionDetails: createRBACQuery(
    fetchApiClient.rbac.retrieveRbacPermission,
  ),
  endpoints: createRBACQuery(fetchApiClient.rbac.listRbacEndpoints, {
    placeholderData: keepPreviousData,
  }),
  resources: createRBACQuery(fetchApiClient.rbac.listRbacResources, {
    placeholderData: keepPreviousData,
  }),
  resourceDetails: createRBACQuery(fetchApiClient.rbac.retrieveRbacResource),
  roles: createRBACQuery(fetchApiClient.rbac.listRbacRoles, {
    placeholderData: keepPreviousData,
  }),
  roleDetails: createRBACQuery(fetchApiClient.rbac.retrieveRbacRole),
};

export const rbacPermissionsAtomFilter = atomWithDebounce<ListFilterState>({
  page: 1,
  search: "",
});

export const rbacResourceAtomFilter = atomWithDebounce<ListFilterState>({
  page: 1,
  search: "",
});

export const rbacRolesAtomFilter = atomWithDebounce<ListFilterState>({
  page: 1,
  search: "",
});

export const rbacAtoms = {
  permissionsFilter: rbacPermissionsAtomFilter,
  permissions: atomWithSuspenseQuery((get) =>
    rbacQueries.permissions(
      get(
        rbacPermissionsAtomFilter.debouncedValueAtom,
      ) as RBACParams<"listRbacPermissions">,
    ),
  ),
  permissionDetails: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => rbacQueries.permissionDetails(Number(id))),
  ),

  resourcesFilter: rbacResourceAtomFilter,
  resources: atomWithSuspenseQuery((get) =>
    rbacQueries.resources(
      get(
        rbacResourceAtomFilter.debouncedValueAtom,
      ) as RBACParams<"listRbacResources">,
    ),
  ),
  resourceDetails: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => rbacQueries.resourceDetails(Number(id))),
  ),
  rolesFilter: rbacRolesAtomFilter,
  roles: atomWithSuspenseQuery((get) =>
    rbacQueries.roles(
      get(
        rbacRolesAtomFilter.debouncedValueAtom,
      ) as RBACParams<"listRbacRoles">,
    ),
  ),
  roleDetails: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => rbacQueries.roleDetails(Number(id))),
  ),

  endpoints: atomWithSuspenseQuery(() => rbacQueries.endpoints()),
};
