import { Input, Form, Select, Typography } from "antd";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LayoutDetailsForm, LayoutDetailsFormProps } from "@/components";
import { Permission } from "@/types/Api";

import { PermissionsActions } from "../-data";
import { useInfiniteRBACResource } from "../-data/hooks";

interface Props
  extends LayoutDetailsFormProps<
    Omit<Permission, "resource"> & { resource: number }
  > {}

const PermissionsForm = (props: Props) => {
  const { form } = props;
  const { t } = useTranslation("dashboard");
  const query = useInfiniteRBACResource();

  const options = query.data?.pages.flatMap((resource) =>
    resource.data?.results?.flatMap((item) => ({
      label: item.name,
      value: item.id,
    })),
  );

  return (
    <LayoutDetailsForm {...props}>
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.name")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <Input
                {...field}
                disabled={!props.isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="resource"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.rbac.resource")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <Select
                {...field}
                disabled={!props.isEdit}
                options={options}
                placeholder={t("menu.select", {
                  what: t("menu.rbac.resource"),
                })}
                loading={query.isFetchingNextPage || query.isLoading}
                onPopupScroll={(e) => {
                  const { scrollTop, offsetHeight, scrollHeight } =
                    e.target as HTMLDivElement;
                  if (
                    query.hasNextPage &&
                    !query.isFetching &&
                    scrollTop + offsetHeight === scrollHeight
                  ) {
                    void query.fetchNextPage();
                  }
                }}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="action"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.action")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <Select
                {...field}
                value={field.value}
                disabled={!props.isEdit}
                options={PermissionsActions}
                placeholder={t("menu.select", {
                  what: t("menu.action"),
                })}
              />
            </Form.Item>
          );
        }}
      />
      {!props.isEdit && (
        <Controller
          control={form.control}
          name="code"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  // eslint-disable-next-line i18next/no-literal-string
                  <Typography.Text className="font-medium">
                    Code
                  </Typography.Text>
                }
                validateStatus={error ? "error" : ""}
                help={error?.message}
                required
                className="col-span-2"
              >
                <Input
                  {...field}
                  readOnly
                  disabled={!props.isEdit}
                  placeholder={t("menu.editHere")}
                />
              </Form.Item>
            );
          }}
        />
      )}
    </LayoutDetailsForm>
  );
};

export default PermissionsForm;
