import { keepPreviousData } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { createQuery } from "@/lib/utils/tanstack-query";
import { PaginateFilterState } from "@/types";
import { SurveyParams } from "@/types/ApiParams";

const createSurveyQuery = createQuery("SURVEY");

export const surveyQueries = {
  list: createSurveyQuery(
    fetchApiClient.surveys.getListSurveyFormWithPagination,
    {
      placeholderData: keepPreviousData,
    },
  ),
  details: createSurveyQuery(fetchApiClient.surveys.getDetailASurveyForm),
  listAnswer: createSurveyQuery(
    fetchApiClient.surveys.getListAnswersWithPagination,
    {
      placeholderData: keepPreviousData,
    },
  ),
  answerDetails: createSurveyQuery(fetchApiClient.surveys.getDetailAnswer),
};

const surveysAtomFilter = atomWithDebounce<PaginateFilterState | undefined>({
  page: 1,
  search: "",
});

const surveyAnswersAtomFilter = atomWithDebounce<
  | (SurveyParams<"getListAnswersWithPagination"> & PaginateFilterState)
  | undefined
>({
  page: 1,
  search: "",
});

export const surveyAtoms = {
  listFilter: surveysAtomFilter,
  list: atomWithSuspenseQuery((get) =>
    surveyQueries.list(get(surveysAtomFilter.debouncedValueAtom)),
  ),
  listAnswerFilter: surveyAnswersAtomFilter,
  listAnswer: atomWithSuspenseQuery((get) =>
    surveyQueries.listAnswer(get(surveyAnswersAtomFilter.debouncedValueAtom)),
  ),
  details: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => surveyQueries.details(id)),
  ),
  answerDetails: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => surveyQueries.answerDetails(+id)),
  ),
};
