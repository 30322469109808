import { createFileRoute, redirect } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";

import { PendingComponent } from "@/components";
import { userQueries } from "@/jotai-atoms/user";
import { checkUserRole } from "@/lib/utils";

export const Route = createFileRoute("/_auth/_dashboard/")({
  beforeLoad: async ({ context: { queryClient } }) => {
    // Root route should validate the index route for each role!
    const data = queryClient.getQueryData(userQueries.userInfo().queryKey);
    const isRole = checkUserRole(data?.user.groups ?? []);
    if (isRole("ADMIN") || isRole("STAFF")) {
      return redirect({ to: "/customers" });
    }
    if (isRole("OP")) {
      return redirect({ to: "/facilities" });
    }
    if (isRole("SAM")) {
      return redirect({ to: "/events" });
    }
    notification.error({
      message: t("menu.login.notAuthorize", { ns: "dashboard" }),
      description: t("menu.login.contactAdmin", { ns: "dashboard" }),
    });
    // Await to show noti and user can see it!
    await new Promise((res) => {
      setTimeout(() => {
        res(100);
      }, 1250);
    });
    // return to await signout redirect
    // return auth.signoutRedirect();
  },
  pendingMinMs: 0,
  pendingMs: 0,
  pendingComponent: () => <PendingComponent />,
});
