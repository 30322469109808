import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { Permission } from "@/types/Api";

import PermissionsForm from "../../../-components/PermissionsForm";
import { rbacPermissionSchema } from "../../../-data";
import {
  useRBACPermissionDetails,
  useUpdateRBACPermissions,
} from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/rbac/permissions/_details/$id/edit",
)({
  component: () => <PermissionsDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function PermissionsDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/rbac/permissions/_details/$id/edit",
  });
  const { resolver } = useYupResolver<
    Omit<Permission, "resource"> & { resource: number }
  >(rbacPermissionSchema);
  const { data } = useRBACPermissionDetails(id);
  const form = useForm<Omit<Permission, "resource"> & { resource: number }>({
    defaultValues: {
      name: data?.name,
      resource: data?.resource.id,
      action: data?.action,
    },
    resolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate } = useUpdateRBACPermissions();

  const handleSubmit = (params: {
    action: "VIEW" | "CREATE" | "UPDATE" | "DELETE";
    name: string;
    resource: number;
  }) => {
    mutate(
      {
        id,
        data: params,
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: t("menu.rbac.permission").toLowerCase(),
            }),
          });
          void navigate({ to: "/rbac/permissions/$id", params: { id } });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.rbac.permission").toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <PermissionsForm
        onClose={() => void navigate({ to: "/rbac/permissions" })}
        title={t("menu.details")}
        form={form}
        onSubmit={handleSubmit}
        isEdit
      />
    </MainDashboard>
  );
}
