import { FunnelPlotOutlined } from "@ant-design/icons";
import { Dropdown, Typography, Button, Select, notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import { cn } from "@/lib/utils";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

import { useInfiniteSurveys, useSurveyAnswersFilter } from "../-data/hooks";

interface Props {
  isListLoading?: boolean;
  onAddNewClick?: () => void;
  onSearchChange?: (value: string) => void;
  search?: string;
  title?: string;
}

interface FilterType {
  form: number[];
}

const SurveyAnswerHeader = ({
  isListLoading,
  onAddNewClick,
  onSearchChange,
  search,
  title,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const [filter, setFilter] = useState<FilterType>({
    form: [],
  });
  const [open, setOpen] = useState(false);
  const { setFilter: setSurveyFilter } = useSurveyAnswersFilter();
  const query = useInfiniteSurveys();
  const options =
    query.data?.pages[0].data?.results.length === 0
      ? []
      : query.data?.pages.flatMap(
          (resource) =>
            resource.data?.results.flatMap((item) => ({
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              label: item.en_title || item.title || "",
              value: item.id,
            })) ?? [],
        ) ?? [];

  const handleResetFilter = () => {
    setSurveyFilter({
      page: 1,
      search: "",
    });
    setFilter({
      form: [],
    });
    setOpen(false);
  };

  const handleApplyFilter = () => {
    setOpen(false);
    setSurveyFilter((prev) => ({
      ...prev,
      ...filter,
    }));
  };
  const shouldDisableResetBtn = filter.form.length === 0;

  const handleExport = async () => {
    try {
      const { data } = await fetchApiClient.surveys.exportListSurveyAnswers({
        form: filter.form,
      });
      if (data?.ok) {
        notification.success({
          message: t("menu.success"),
          description: t("menu.surveys.sendNotification"),
        });
      } else {
        notification.error({
          message: t("menu.error"),
          description: t("menu.somethingWentWrong"),
        });
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <ListHeader
      isLoading={isListLoading}
      title={title}
      onAddNewClick={onAddNewClick}
      onExport={handleExport as () => void}
      onChange={onSearchChange}
      search={search}
      filter={
        <Dropdown
          open={open}
          trigger={["click"]}
          onOpenChange={setOpen}
          menu={{
            selectable: true,
            selectedKeys:
              filter.form.length > 0
                ? filter.form.map((item) => item.toString())
                : [],
            className: "w-[323px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                      loading={isListLoading}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.surveys.survey")}
                    </Typography.Text>
                    <Select
                      mode="multiple"
                      placeholder={t("menu.select", {
                        what: t("menu.surveys.survey").toLowerCase(),
                      })}
                      className="w-full"
                      options={options}
                      loading={query.isFetchingNextPage || query.isLoading}
                      onPopupScroll={(e) => {
                        const { scrollTop, offsetHeight, scrollHeight } =
                          e.target as HTMLDivElement;
                        if (
                          query.hasNextPage &&
                          !query.isFetching &&
                          scrollTop + offsetHeight === scrollHeight
                        ) {
                          void query.fetchNextPage();
                        }
                      }}
                      onChange={(form) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            form,
                          };
                        });
                      }}
                      showArrow
                      maxTagTextLength={24}
                      value={filter.form}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "event-status",
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default SurveyAnswerHeader;
