import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import CheckItem from "@/components/CheckItem";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import {
  calculateTableRecordByIndex,
  formatFullDateTime,
  upperFirstLetter,
} from "@/lib/utils";

import SurveyHeader from "../-components/SurveyHeader";
import { useSurveys, useSurveysFilter } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/surveys/_list/")({
  component: () => <SurveysPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function SurveysPage() {
  const { t } = useTranslation("dashboard");
  const { data, isFetching } = useSurveys();
  const { filter, setFilter } = useSurveysFilter();
  const onRow = useOnRowTable({ to: "/surveys/$id" });
  const { handlePaginateChange, handleSearchChange } =
    useCreateHandleFilterFunc(setFilter);
  const dataSource = useDataSource(data?.results);
  const navigate = useNavigate();
  const handleAddNew = () => {
    void navigate({ to: "/surveys/add" });
  };

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <SurveyHeader
          search={filter?.search}
          onAddNewClick={handleAddNew}
          title={t("menu.list")}
          onSearchChange={handleSearchChange}
          isListLoading={isFetching}
        />
        <Table
          loading={isFetching}
          onRow={onRow}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "id",
              width: "1ch",
              render: (_, record, index) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/surveys/$id"
                    params={{ id: record.id.toString() }}
                  >
                    {calculateTableRecordByIndex(index, filter?.page)}
                  </Link>
                );
              },
            },
            {
              title: t("menu.title"),
              key: "title",
              render: (_, record) => {
                return <span>{record.title}</span>;
              },
            },
            {
              title: t("menu.enTitle"),
              key: "en_title",
              render: (_, record) => {
                return <span>{record.en_title}</span>;
              },
            },
            {
              title: t("menu.published"),
              key: "published",
              className: "!text-center",
              render: (_, record) => {
                return <CheckItem checked={!!record.published} />;
              },
            },
            {
              title: t("menu.OASIS.type"),
              key: "type",
              render: (_, record) => {
                return (
                  <span>{upperFirstLetter(record.service_type ?? "")}</span>
                );
              },
            },
            {
              title: t("menu.news.createdAt"),
              key: "created_at",
              render: (_, record) => {
                return <span>{formatFullDateTime(record.created_at)}</span>;
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
