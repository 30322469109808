import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { Role } from "@/types/Api";

import RoleForm from "../-components/RoleForm";
import { rbacRoleSchema } from "../-data";
import { useCreateRBACRole, useUpsertRolePermissions } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/rbac/roles/add")({
  component: () => <RoleDetailsPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function RoleDetailsPage() {
  const { resolver } = useYupResolver<Role & { permissions?: number[] }>(
    rbacRoleSchema,
  );
  const form = useForm<Role & { permissions?: number[] }>({
    defaultValues: {
      name: "",
      description: "",
      permissions: [],
    },
    resolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending: isCreating } = useCreateRBACRole();
  const { mutate: upsertPermissions, isPending: isUpsertPending } =
    useUpsertRolePermissions();

  const handleSubmit = (params: Role & { permissions?: number[] }) => {
    const { permissions, ...rest } = params;
    mutate(rest, {
      onSuccess: (data) => {
        if ((permissions?.length ?? 0) > 0 && data?.id) {
          upsertPermissions(
            {
              roleId: data.id.toString(),
              permissions: permissions?.map((id) => ({ id })),
            },
            {
              onSuccess: () => {
                notification.success({
                  message: t("menu.success"),
                  description: t("menu.update.success", {
                    what: t("menu.rbac.role").toLowerCase(),
                  }),
                });
              },
              onError: () => {
                notification.warn({
                  message: t("menu.success"),
                  description: t(
                    "menu.rbac.updateRoleSuccessWithoutPermissions",
                  ),
                });
              },
              onSettled: () => {
                void navigate({ to: "/rbac/roles" });
              },
            },
          );
        }
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.update.error", {
            what: t("menu.rbac.role").toLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <RoleForm
        onClose={() => void navigate({ to: "/rbac/roles" })}
        title={t("menu.details")}
        form={form}
        isEdit
        onSubmit={handleSubmit}
        isSubmitLoading={isCreating || isUpsertPending}
      />
    </MainDashboard>
  );
}
