import {
  BellOutlined,
  CalendarOutlined,
  GiftOutlined,
  MessageOutlined,
  ProfileOutlined,
  TeamOutlined,
  SettingOutlined,
  BookOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useLocation, Link } from "@tanstack/react-router";
import { MenuProps } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useUserInfo } from "@/jotai-atoms/user";
import { checkUserRole } from "@/lib/utils";

import { RouteFeaturesType, RouteFeatureSupport, uniqueBy } from "./index";

export type MenuItem = Required<MenuProps>["items"][number] & { order: number };

const useRoutesByFeatures = (): Record<RouteFeaturesType, MenuItem> & {
  isSAM: boolean;
  isAdmin: boolean;
  isStaff: boolean;
  isOP: boolean;
  isSuperUser: boolean;
} => {
  const { t } = useTranslation("dashboard");
  const { data: userInfo } = useUserInfo();
  const userGroup = userInfo?.user.groups ?? [];
  const isRole = checkUserRole(userGroup);
  const isSAM = isRole("SAM");
  const isAdmin = isRole("ADMIN");
  const isStaff = isRole("STAFF");
  const isOP = isRole("OP");
  const isSuperUser = !!userInfo?.user.is_superuser;

  const oasisProgram = {
    label: t("menu.OASIS.OASISProgram", {
      ns: "dashboard",
    }),
    key: "/oasis-group",
    icon: <BookOutlined />,
    order: 7,
    children: [
      {
        label: (
          <Link to="/oasis">
            {t("menu.OASIS.OASISDashboard", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/oasis",
      },
      {
        label: (
          <Link to="/oasis/orientation">
            {t("menu.OASIS.orientation", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/oasis/orientation",
      },
      {
        label: (
          <Link to="/oasis/skill">
            {t("menu.OASIS.skill", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/oasis/skill",
      },
      {
        label: (
          <Link to="/oasis/advisor">
            {t("menu.OASIS.advisor", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/oasis/advisor",
      },
      {
        label: (
          <Link to="/oasis/advising-group">
            {t("menu.OASIS.advisingGroup", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/oasis/advising-group",
      },
    ],
  };

  const surveys = {
    label: t("menu.surveys.survey", {
      ns: "dashboard",
    }),
    key: "/surveys-group",
    icon: <SmileOutlined />,
    order: 8,
    children: [
      {
        label: (
          <Link to="/surveys">
            {t("menu.surveys.surveys", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/surveys",
      },
      {
        label: (
          <Link to="/surveys/answers">
            {t("menu.surveys.answers", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/surveys/answers",
      },
    ],
  };

  const facilities = {
    label: t("menu.facilities.eventFacilities", {
      ns: "dashboard",
    }),
    key: "/facilities",
    icon: <CalendarOutlined />,
    order: 9,
    children: [
      {
        label: (
          <Link to="/facilities/management">
            {t("menu.facilities.management", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/facilities/management",
      },
      {
        label: (
          <Link to="/facilities/requests">
            {t("menu.facilities.requests", {
              ns: "dashboard",
            })}
          </Link>
        ),
        key: "/facilities/requests",
      },
    ],
  };

  const targetCustomer = {
    label: t("menu.targetCustomer.targetCustomer", { ns: "dashboard" }),
    key: "customers",
    icon: <TeamOutlined />,
    children: [
      {
        label: (
          <Link to="/customers">{t("menu.list", { ns: "dashboard" })}</Link>
        ),
        key: "/customers",
      },
    ],
    order: 1,
  };

  const notification = {
    label: t("menu.notifications", { ns: "dashboard" }),
    key: "notifications",
    icon: <BellOutlined />,
    order: 2,
    children: [
      {
        label: (
          <Link to="/notifications">{t("menu.list", { ns: "dashboard" })}</Link>
        ),
        key: "/notifications",
      },
      {
        label: (
          <Link to="/notifications/types">
            {t("menu.notification.types", { ns: "dashboard" })}
          </Link>
        ),
        key: "/notifications/types",
      },
    ],
  };

  const news = {
    label: t("menu.news.news", { ns: "dashboard" }),
    icon: <ProfileOutlined />,
    key: "news",
    order: 3,
    children: [
      {
        label: <Link to="/news">{t("menu.list", { ns: "dashboard" })}</Link>,
        key: "/news",
      },
      {
        label: (
          <Link to="/news/category">
            {t("menu.news.categories", { ns: "dashboard" })}
          </Link>
        ),
        key: "/news/category",
      },
    ],
  };

  const events = {
    label: t("menu.events.single", { ns: "dashboard" }),
    icon: <CalendarOutlined />,
    key: "events",
    children: [
      {
        label: (
          <Link to="/events">
            {t("menu.events.single", { ns: "dashboard" })}
          </Link>
        ),
        key: "/events",
      },
      {
        label: (
          <Link to="/events/speakers">
            {t("menu.speaker.list", { ns: "dashboard" })}
          </Link>
        ),
        key: "/events/speakers",
      },
      {
        label: (
          <Link to="/events/types">
            {t("menu.events.types", { ns: "dashboard" })}
          </Link>
        ),
        key: "/events/types",
      },
      {
        label: (
          <Link to="/events/groups">
            {t("menu.events.group", { ns: "dashboard" })}
          </Link>
        ),
        key: "/events/groups",
      },
    ],
    order: 4,
  };

  const feedbacks = {
    label: t("menu.feedbacks", { ns: "dashboard" }),
    icon: <MessageOutlined />,
    key: "feedbacks",
    order: 5,
    children: [
      {
        label: (
          <Link to="/feedbacks">{t("menu.list", { ns: "dashboard" })}</Link>
        ),
        key: "/feedbacks",
      },
    ],
  };

  const benefits = {
    label: t("menu.benefits.benefits", { ns: "dashboard" }),
    key: "/benefits-group",
    icon: <GiftOutlined />,
    order: 6,
    children: [
      {
        label: (
          <Link to="/benefits">{t("menu.list", { ns: "dashboard" })}</Link>
        ),
        key: "/benefits",
      },
      {
        label: (
          <Link to="/benefits/types">
            {t("menu.benefits.type.types", { ns: "dashboard" })}
          </Link>
        ),
        key: "/benefits/types",
      },
    ],
  };

  const system = {
    label: t("menu.config.system.system", { ns: "dashboard" }),
    key: "system",
    icon: <SettingOutlined />,
    order: 10,
    children: [
      {
        label: (
          <Link to="/config">
            {t("menu.config.system.config", { ns: "dashboard" })}
          </Link>
        ),
        key: "/config",
      },
      {
        label: (
          <Link to="/cache">
            {t("menu.config.mobile.cache", { ns: "dashboard" })}
          </Link>
        ),
        key: "/cache",
      },
    ],
  };

  const rbac = {
    label: t("menu.rbac.rbac", { ns: "dashboard" }),
    key: "rbac",
    icon: <SettingOutlined />,
    order: 11,
    children: [
      {
        label: (
          <Link to="/rbac/permissions">
            {t("menu.rbac.permission", { ns: "dashboard" })}
          </Link>
        ),
        key: "/rbac/permissions",
      },
      {
        label: (
          <Link to="/rbac/roles">
            {t("menu.rbac.role", { ns: "dashboard" })}
          </Link>
        ),
        key: "/rbac/roles",
      },
      {
        label: (
          <Link to="/rbac/resources">
            {t("menu.rbac.resource", { ns: "dashboard" })}
          </Link>
        ),
        key: "/rbac/resources",
      },
    ],
  };

  return {
    oasisProgram,
    surveys,
    facilities,
    targetCustomer,
    notification,
    news,
    feedbacks,
    benefits,
    system,
    events,
    rbac,
    isSAM,
    isStaff,
    isAdmin,
    isOP,
    isSuperUser,
  };
};

export const useMenuItems = () => {
  const { pathname } = useLocation();
  const { isSAM, isAdmin, isStaff, isOP, isSuperUser, ...allRoutes } =
    useRoutesByFeatures();

  const menuItems: MenuItem[] = useMemo(() => {
    if (isAdmin) {
      return RouteFeatureSupport.ADMIN.supportRoute.map(
        (item) => allRoutes[item],
      );
    }
    const routes = [];

    if (isStaff) {
      routes.push(
        ...RouteFeatureSupport.STAFF.supportRoute.map(
          (item) => allRoutes[item],
        ),
      );
    }
    if (isSAM) {
      routes.push(
        ...RouteFeatureSupport.SAM.supportRoute.map((item) => allRoutes[item]),
      );
    }
    if (isOP) {
      routes.push(
        ...RouteFeatureSupport.OP.supportRoute.map((item) => allRoutes[item]),
      );
    }
    return uniqueBy(routes, (item) => item.key);
  }, [allRoutes, isAdmin, isOP, isSAM, isStaff]).sort(
    (a, b) => a.order - b.order,
  );

  if (isSuperUser) {
    menuItems.push(
      ...RouteFeatureSupport.SUPER_USER.supportRoute.map(
        (item) => allRoutes[item],
      ),
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const groupKeys: string[] = menuItems.map((item) => item.key);

  const levels = pathname.split("/").filter((item) => item.length > 0);
  const currentGroup = levels[0];

  const matches = menuItems
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .map((item) => item.children || [])
    .flat()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .filter((item) => pathname.includes(item.key))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    .sort((a, b) => b.key.length - a.key.length);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const currentPage: string = matches.length > 0 ? matches[0].key : "/";

  return { menuItems, currentGroup, currentPage, groupKeys };
};
