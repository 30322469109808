import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { Resource } from "@/types/Api";

import ResourceForm from "../../../-components/ResourceForm";
import { useRBACResourceDetails } from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/rbac/resources/_details/$id/",
)({
  component: () => <ResourceDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function ResourceDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/rbac/resources/_details/$id/",
  });
  const { data } = useRBACResourceDetails(id);
  const form = useForm<Resource>({ defaultValues: data });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  return (
    <MainDashboard>
      <ResourceForm
        onEdit={() =>
          void navigate({ to: "/rbac/resources/$id/edit", params: { id } })
        }
        onClose={() => void navigate({ to: "/rbac/resources" })}
        title={t("menu.details")}
        form={form}
      />
    </MainDashboard>
  );
}
