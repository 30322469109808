import { TFunction } from "i18next";
import * as yup from "yup";

export const rbacResourceSchema = (t: TFunction) =>
  yup.object().shape({
    endpoint: yup.string().required(
      t("menu.validation.required", {
        what: "Endpoint",
      }),
    ),
    name: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.name", { ns: "dashboard" }),
      }),
    ),
  });

export const rbacPermissionSchema = (t: TFunction) =>
  yup.object().shape({
    action: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.action", { ns: "dashboard" }),
      }),
    ),
    name: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.name", { ns: "dashboard" }),
      }),
    ),
    resource: yup.number().required(
      t("menu.validation.required", {
        what: t("menu.rbac.resource", { ns: "dashboard" }),
      }),
    ),
  });

export const rbacRoleSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(
      t("menu.validation.required", {
        what: t("menu.name", { ns: "dashboard" }),
      }),
    ),
  });

const PermissionsAction = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const PermissionsActions = [
  { label: "View", value: PermissionsAction.VIEW },
  { label: "Create", value: PermissionsAction.CREATE },
  { label: "Update", value: PermissionsAction.UPDATE },
  { label: "Delete", value: PermissionsAction.DELETE },
];
