import { FunnelPlotOutlined } from "@ant-design/icons";
import { Dropdown, Typography, Checkbox, Button, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import { cn, upperFirstLetter } from "@/lib/utils";
import { SurveyFormAdmin } from "@/types/Api";

import { useSurveysFilter } from "../-data/hooks";

interface Props {
  isListLoading?: boolean;
  onAddNewClick?: () => void;
  onSearchChange?: (value: string) => void;
  search?: string;
  title?: string;
}

interface FilterType {
  published?: boolean;
  service_type: Exclude<SurveyFormAdmin["service_type"], undefined | null>[];
}

const SurveyHeader = ({
  isListLoading,
  onAddNewClick,
  onSearchChange,
  search,
  title,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const [filter, setFilter] = useState<FilterType>({
    service_type: [],
  });
  const [open, setOpen] = useState(false);
  const { setFilter: setSurveyFilter } = useSurveysFilter();

  const handleResetFilter = () => {
    setSurveyFilter({
      page: 1,
      search: "",
    });
    setFilter({
      published: undefined,
      service_type: [],
    });
    setOpen(false);
  };

  const handleApplyFilter = () => {
    setOpen(false);
    setSurveyFilter((prev) => ({
      ...prev,
      ...filter,
    }));
  };
  const shouldDisableResetBtn =
    filter.published === undefined && filter.service_type.length === 0;

  return (
    <ListHeader
      isLoading={isListLoading}
      title={title}
      onAddNewClick={onAddNewClick}
      onChange={onSearchChange}
      search={search}
      filter={
        <Dropdown
          open={open}
          trigger={["click"]}
          onOpenChange={setOpen}
          menu={{
            selectable: true,
            selectedKeys:
              filter.service_type.length > 0
                ? (filter.service_type as string[])
                : [],
            className: "w-[232px]",
            items: [
              {
                label: (
                  <Stack className="justify-between gap-6">
                    <Button
                      size="small"
                      type="text"
                      onClick={handleResetFilter}
                      disabled={shouldDisableResetBtn}
                      className={cn(
                        !shouldDisableResetBtn &&
                          "text-[var(--ant-primary-color)]",
                      )}
                    >
                      {t("menu.button.reset")}
                    </Button>
                    <Button
                      size="small"
                      type="text"
                      className="bg-brand-primary text-white"
                      onClick={handleApplyFilter}
                      loading={isListLoading}
                    >
                      {t("menu.button.ok")}
                    </Button>
                  </Stack>
                ),
                className: cn("hover:bg-transparent cursor-default px-2"),
                key: "action",
              },
              { type: "divider" },
              {
                label: (
                  <div className="flex flex-col gap-2">
                    <Typography.Text className="font-medium">
                      {t("menu.OASIS.type")}
                    </Typography.Text>
                    <Select
                      mode="multiple"
                      placeholder={t("menu.select", {
                        what: t("menu.OASIS.type").toLowerCase(),
                      })}
                      className="w-full"
                      options={["COUNSELLING"].map((value) => ({
                        label: upperFirstLetter(value),
                        value,
                      }))}
                      onChange={(service_type) => {
                        setFilter((prev) => {
                          return {
                            ...prev,
                            service_type,
                          };
                        });
                      }}
                      showArrow
                      maxTagTextLength={24}
                      value={filter.service_type}
                    />
                  </div>
                ),
                className: "hover:bg-transparent bg-transparent",
                key: "event-status",
              },
              {
                label: (
                  <Typography.Text className="font-medium">
                    {t("menu.published")}
                  </Typography.Text>
                ),
                type: "group",
                key: "published",
                className: "mx-0 ",
                children: [
                  {
                    key: "published-true",
                    label: (
                      <Checkbox
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setFilter((prev) => {
                            return {
                              ...prev,
                              published: checked ? true : undefined,
                            };
                          });
                        }}
                        checked={filter.published}
                      >
                        {t("menu.published")}
                      </Checkbox>
                    ),
                  },
                  {
                    key: "published-false",
                    label: (
                      <Checkbox
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setFilter((prev) => {
                            return {
                              ...prev,
                              published: checked ? false : undefined,
                            };
                          });
                        }}
                        checked={
                          typeof filter.published === "boolean" &&
                          !filter.published
                        }
                      >
                        {t("menu.unpublished")}
                      </Checkbox>
                    ),
                  },
                ],
              },
            ],
          }}
        >
          <Stack
            className="cursor-pointer gap-1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span>{t("menu.filter")}</span>
            <FunnelPlotOutlined className="text-[#8C8C8C]" />
          </Stack>
        </Dropdown>
      }
    />
  );
};

export default SurveyHeader;
