import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { Role } from "@/types/Api";

import RoleForm from "../../../-components/RoleForm";
import {
  useRBACRoleDetails,
  useUpdateRBACRole,
  useUpsertRolePermissions,
} from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/rbac/roles/_details/$id/edit",
)({
  component: () => <RoleDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function RoleDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/rbac/roles/_details/$id/edit",
  });
  const { data } = useRBACRoleDetails(id);
  const form = useForm<Role & { permissions?: number[] }>({
    defaultValues: {
      ...data,
      permissions: data?.permissions?.map((p) => p.id) ?? [],
    },
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending: isUpdating } = useUpdateRBACRole();
  const { mutate: upsertPermissions, isPending: isUpsertPending } =
    useUpsertRolePermissions();

  const handleSubmit = (params: Role & { permissions?: number[] }) => {
    const { permissions, ...rest } = params;
    mutate(
      { id, data: rest },
      {
        onSuccess: () => {
          if ((permissions?.length ?? 0) > 0) {
            upsertPermissions(
              {
                roleId: id,
                permissions: permissions?.map((id) => ({ id })),
              },
              {
                onSuccess: () => {
                  notification.success({
                    message: t("menu.success"),
                    description: t("menu.update.success", {
                      what: t("menu.rbac.role").toLowerCase(),
                    }),
                  });
                },
                onError: () => {
                  notification.warn({
                    message: t("menu.success"),
                    description: t(
                      "menu.rbac.updateRoleSuccessWithoutPermissions",
                    ),
                  });
                },
                onSettled: () => {
                  void navigate({ to: "/rbac/roles/$id", params: { id } });
                },
              },
            );
          }
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.rbac.role").toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <RoleForm
        onClose={() => void navigate({ to: "/rbac/roles" })}
        title={t("menu.details")}
        form={form}
        isEdit
        onSubmit={handleSubmit}
        isSubmitLoading={isUpdating || isUpsertPending}
      />
    </MainDashboard>
  );
}
