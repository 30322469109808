import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { QUESTION_TYPE } from "@/lib/constants";
import { ISurveyForm } from "@/types";

import SurveyForm from "../../../-components/SurveyForm";
import { counsellingSchema } from "../../../-data";
import { useSurvey } from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/surveys/_list/_details/$id/",
)({
  component: () => <SurveyDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function SurveyDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/surveys/_list/_details/$id/",
  });
  const { data } = useSurvey(id);
  const { resolver } = useYupResolver<ISurveyForm>(counsellingSchema);
  const form = useForm<ISurveyForm>({
    defaultValues: {
      ...data,
      questions: data?.questions?.map((item) => {
        return {
          ...item,
          question_type:
            item.question_type === QUESTION_TYPE.CHOICES
              ? (item.max_choices ?? 0) > 1
                ? "CHECKBOXES"
                : "MULTIPLE_CHOICE"
              : item.question_type,
        };
      }),
    },
    resolver,
  });

  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const handleOnClose = () => {
    void navigate({ to: "/surveys" });
  };

  return (
    <MainDashboard>
      <SurveyForm
        title={t("menu.surveys.survey")}
        form={form}
        onClose={handleOnClose}
        onEdit={() => {
          void navigate({ to: "/surveys/$id/edit", params: { id } });
        }}
      />
    </MainDashboard>
  );
}
