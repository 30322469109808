import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { Resource } from "@/types/Api";

import ResourceForm from "../../../-components/ResourceForm";
import { rbacResourceSchema } from "../../../-data";
import {
  useRBACResourceDetails,
  useUpdateRBACResource,
} from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/rbac/resources/_details/$id/edit",
)({
  component: () => <ResourceDetailsEditPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function ResourceDetailsEditPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/rbac/resources/_details/$id/edit",
  });
  const { data } = useRBACResourceDetails(id);
  const { resolver } = useYupResolver<Resource>(rbacResourceSchema);
  const form = useForm<Resource>({ defaultValues: data, resolver });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdateRBACResource();

  const handleSubmit = (params: Resource) => {
    mutate(
      { id, data: params },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: t("menu.rbac.resource").toLowerCase(),
            }),
          });
          void navigate({ to: "/rbac/resources/$id", params: { id } });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.rbac.resource").toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <ResourceForm
        onClose={() => void navigate({ to: "/rbac/resources" })}
        title={t("menu.details")}
        form={form}
        onSubmit={handleSubmit}
        isEdit
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
}
