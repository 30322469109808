import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { Permission } from "@/types/Api";

import PermissionsForm from "../-components/PermissionsForm";
import { rbacPermissionSchema } from "../-data";
import { useCreateRBACPermissions } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/rbac/permissions/add")({
  component: () => <PermissionsDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function PermissionsDetailsPage() {
  const { resolver } = useYupResolver<
    Omit<Permission, "resource"> & { resource: number }
  >(rbacPermissionSchema);
  const form = useForm<Omit<Permission, "resource"> & { resource: number }>({
    defaultValues: {
      name: "",
    },
    resolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate } = useCreateRBACPermissions();

  const handleSubmit = (params: {
    action: "VIEW" | "CREATE" | "UPDATE" | "DELETE";
    name: string;
    resource: number;
  }) => {
    mutate(params, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.rbac.permission").toLowerCase(),
          }),
        });
        void navigate({ to: "/rbac/permissions" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.rbac.permission").toLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <PermissionsForm
        onClose={() => void navigate({ to: "/rbac/permissions" })}
        title={t("menu.details")}
        form={form}
        onSubmit={handleSubmit}
        isEdit
      />
    </MainDashboard>
  );
}
