import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { Button, notification } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { mappingContentToBase64 } from "@/lib/utils";
import { NotificationFormType } from "@/types";
import { NotificationType, ReadTargetCustomer } from "@/types/Api";

import NotificationForm from "../../../-components/NotificationForm";
import {
  useNotiFormDetail,
  usePushNotification,
  useUpdateNotificationDetails,
} from "../../../-data/hooks";
import { notificationDetailsLoader } from "../../../-data/loaders";

export const Route = createFileRoute(
  "/_auth/_dashboard/notifications/_list/(details)/$id/edit",
)({
  loader: notificationDetailsLoader,
  component: () => <NotificationEditPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
});

function NotificationEditPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/notifications/_list/$id/edit",
  });

  const {
    notiTypes,
    notificationDetails,
    form,
    events,
    statistic,
    targetCustomerList,
  } = useNotiFormDetail(id.toString());

  const { mutate, isPending } = useUpdateNotificationDetails();
  const { t } = useTranslation("dashboard");
  const { mutate: pushNoti, isPending: isPushingNoti } = usePushNotification();
  const navigate = useNavigate();

  const handlePushNoti = () => {
    if (notificationDetails?.id) {
      pushNoti(notificationDetails.id.toString());
    }
  };

  const onSubmit = (data: NotificationFormType) => {
    const mutateData = mappingContentToBase64(data, "content", "en_content");
    mutate(
      {
        ...mutateData,
        id: notificationDetails?.id?.toString() ?? "",
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: t("menu.notification.notification"),
            }),
          });
          void navigate({
            to: "/notifications/$id",
            params: { id: notificationDetails?.id?.toString() ?? "" },
          });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.notification.notification"),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <NotificationForm
        title={t("menu.edit")}
        statistic={statistic ?? []}
        form={form}
        isEdit
        notificationType={(notiTypes as NotificationType[] | undefined) ?? []}
        targetCustomers={
          (targetCustomerList as ReadTargetCustomer[] | undefined) ?? []
        }
        events={events ?? []}
        createBy={notificationDetails?.created_by?.email ?? ""}
        onSubmit={onSubmit}
        isSubmitLoading={isPending}
        rightContent={
          <Button
            loading={isPushingNoti}
            className="min-h-[38px] min-w-[114px]"
            type="primary"
            onClick={handlePushNoti}
          >
            {t("menu.send")}
          </Button>
        }
      />
    </MainDashboard>
  );
}
