import { ParseRoute } from "@tanstack/react-router";

import { routeTree } from "@/routeTree.gen";
import { UserRole } from "@/types";

export type RouteFeaturesType =
  | "oasisProgram"
  | "surveys"
  | "facilities"
  | "targetCustomer"
  | "notification"
  | "news"
  | "feedbacks"
  | "benefits"
  | "system"
  | "events"
  | "rbac";

const RoutePathFeatures: Record<
  RouteFeaturesType,
  {
    name: RouteFeaturesType;
    routePath: ParseRoute<typeof routeTree>["fullPath"];
  }
> = {
  benefits: {
    name: "benefits",
    routePath: "/benefits",
  },
  oasisProgram: {
    name: "oasisProgram",
    routePath: "/oasis",
  },
  surveys: {
    name: "surveys",
    routePath: "/surveys",
  },
  targetCustomer: {
    name: "targetCustomer",
    routePath: "/customers",
  },
  facilities: {
    name: "facilities",
    routePath: "/facilities",
  },
  notification: {
    name: "notification",
    routePath: "/notifications",
  },
  news: {
    name: "news",
    routePath: "/news",
  },
  system: {
    name: "system",
    routePath: "/config",
  },
  events: {
    name: "events",
    routePath: "/events",
  },
  feedbacks: {
    name: "feedbacks",
    routePath: "/feedbacks",
  },
  rbac: {
    name: "rbac",
    routePath: "/rbac/permissions",
  },
};

export const RouteFeatureSupport: Record<
  UserRole,
  { supportRoute: RouteFeaturesType[] }
> = {
  SUPER_USER: {
    supportRoute: ["rbac"],
  },
  ADMIN: {
    supportRoute: [
      "oasisProgram",
      "surveys",
      "facilities",
      "targetCustomer",
      "notification",
      "news",
      "feedbacks",
      "benefits",
      "events",
      "system",
    ],
  },
  STAFF: {
    supportRoute: ["surveys", "facilities", "notification", "targetCustomer"],
  },
  OP: {
    supportRoute: ["facilities"],
  },
  SAM: {
    supportRoute: ["events", "oasisProgram", "news"],
  },
};

export const getSupportedRoutePath = (role: UserRole) => {
  return RouteFeatureSupport[role].supportRoute.map(
    (item) => RoutePathFeatures[item].routePath,
  );
};

export const uniqueBy = <T, K>(
  array: T[],
  keySelector: (item: T) => K,
): T[] => {
  const seen = new Set<K>();
  return array.reduce<T[]>((uniqueItems, currentItem) => {
    const key = keySelector(currentItem);
    if (seen.has(key)) {
      return uniqueItems;
    }
    seen.add(key);
    return [...uniqueItems, currentItem];
  }, []);
};
