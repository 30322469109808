import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, Typography, Input, Button } from "antd";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Editor,
  LayoutDetailsForm,
  LayoutDetailsFormProps,
} from "@/components";
import { ISurveyForm } from "@/types";

import Question from "./Question";

interface Props extends LayoutDetailsFormProps<ISurveyForm> {}

const SurveyForm = ({
  form,
  isEdit,
  onEdit,
  onClose,
  onSubmit,
  isSubmitLoading,
  title,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const { control } = form;
  const { append, remove, fields, insert } = useFieldArray({
    control,
    name: "questions",
  });

  return (
    <LayoutDetailsForm
      form={form}
      title={title}
      isEdit={isEdit}
      onEdit={onEdit}
      isSubmitLoading={isSubmitLoading}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Controller
        name="title"
        control={control}
        disabled={!isEdit}
        render={({ field, fieldState: { error } }) => (
          <Form.Item
            label={
              <Typography.Text className="font-medium">
                {t("menu.enTitle")}
              </Typography.Text>
            }
            validateStatus={error ? "error" : ""}
            help={error?.message}
            required
          >
            <Input {...field} placeholder={t("menu.editHere")} />
          </Form.Item>
        )}
      />
      <Controller
        name="en_title"
        control={control}
        disabled={!isEdit}
        render={({ field, fieldState: { error } }) => (
          <Form.Item
            label={
              <Typography.Text className="font-medium">
                {t("menu.enTitle")}
              </Typography.Text>
            }
            validateStatus={error ? "error" : ""}
            help={error?.message}
            required
          >
            <Input {...field} placeholder={t("menu.editHere")} />
          </Form.Item>
        )}
      />

      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.enDescription")}
          </Typography.Text>
        }
        className="col-span-2"
      >
        <Controller
          name="en_description"
          control={control}
          render={({ field }) => (
            <Editor
              placeholder={t("menu.editHere")}
              disabled={!isEdit}
              data={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Form.Item>

      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.counsellingService.question")}
          </Typography.Text>
        }
        className="col-span-2 "
        required
      >
        <div className="border border-solid border-[#d9d9d9] py-4 px-6">
          {fields.length === 0 && (
            <div>
              <p className="mb-2">{t("menu.counsellingService.noQuestion")}</p>
              <Button
                className="border-brand bg-brand-primary"
                type="primary"
                icon={<PlusOutlined />}
                disabled={!isEdit}
                onClick={() => {
                  append({
                    title: "",
                    question_type: "TEXT",
                  });
                }}
              >
                {t("menu.addNew")}
              </Button>
            </div>
          )}
          {fields.map((question, index: number) => {
            return (
              <>
                <Question
                  form={form}
                  key={question.id}
                  disable={!isEdit}
                  index={index}
                />
                <div className="flex justify-between items-center mt-4">
                  <Button
                    className="border-brand bg-brand-primary"
                    type="primary"
                    icon={<PlusOutlined />}
                    disabled={!isEdit}
                    onClick={() => {
                      insert(index + 1, {
                        title: "",
                        question_type: "TEXT",
                      });
                    }}
                  >
                    {t("menu.addNew")}
                  </Button>

                  <Button
                    danger
                    disabled={!isEdit}
                    icon={<DeleteOutlined />}
                    type="primary"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    {t("menu.button.delete")}
                  </Button>
                </div>
                <div
                  style={{ border: "1px dashed #444" }}
                  className=" my-2 mt-4"
                />
              </>
            );
          })}
        </div>
      </Form.Item>
    </LayoutDetailsForm>
  );
};

export default SurveyForm;
