import {
  infiniteQueryOptions,
  keepPreviousData,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";

import { useAppMutation } from "@/hooks/useAppMutation";
import { globalStore } from "@/jotai-atoms";
import { queryClient } from "@/lib/config/queryClient";
import { fetchApiClient } from "@/lib/utils/fetchUtils";

import { rbacAtoms, rbacQueries } from "./store";

export const useRBACPermissions = () => useAtomValue(rbacAtoms.permissions);
export const useRBACPermissionsFilter = () => {
  const filter = useAtomValue(rbacAtoms.permissionsFilter.currentValueAtom);
  const setFilter = useSetAtom(rbacAtoms.permissionsFilter.debouncedValueAtom);
  return { filter, setFilter };
};
export const useRBACPermissionDetails = (id: string) =>
  useAtomValue(rbacAtoms.permissionDetails(id));

export const useCreateRBACPermissions = () =>
  useAppMutation({
    mutationFn: async (data: {
      action: "VIEW" | "CREATE" | "UPDATE" | "DELETE";
      name: string;
      resource: number;
    }) => {
      try {
        await fetchApiClient.rbac.createRbacPermission({
          ...data,
          resource: {
            id: data.resource,
          },
        });
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });

export const useUpdateRBACPermissions = () =>
  useAppMutation({
    mutationFn: async (params: {
      id: string;
      data: {
        action?: "VIEW" | "CREATE" | "UPDATE" | "DELETE";
        name?: string;
        resource?: number;
      };
    }) => {
      try {
        await fetchApiClient.rbac.updateRbacPermission(+params.id, params.data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: rbacQueries.permissionDetails(+id).queryKey,
        });
        rbacAtoms.permissionDetails.remove(id);
      }
    },
  });

export const useDeleteRBACPermissions = () =>
  useAppMutation({
    mutationFn: async (id: string) => {
      try {
        await fetchApiClient.rbac.deleteRbacPermission(+id);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: rbacQueries.permissions({
          page: globalStore.get(rbacAtoms.permissionsFilter.currentValueAtom)
            ?.page,
        } as { search?: string }).queryKey,
        exact: false,
      });
    },
  });

export const useRBACResources = () => useAtomValue(rbacAtoms.resources);
export const useRBACResourcesFilter = () => {
  const filter = useAtomValue(rbacAtoms.resourcesFilter.currentValueAtom);
  const setFilter = useSetAtom(rbacAtoms.resourcesFilter.debouncedValueAtom);
  return { filter, setFilter };
};
export const useRBACResourceDetails = (id: string) =>
  useAtomValue(rbacAtoms.resourceDetails(id));

export const useRBACRoles = () => useAtomValue(rbacAtoms.roles);
export const useRBACRolesFilter = () => {
  const filter = useAtomValue(rbacAtoms.rolesFilter.currentValueAtom);
  const setFilter = useSetAtom(rbacAtoms.rolesFilter.debouncedValueAtom);
  return { filter, setFilter };
};
export const useRBACRoleDetails = (id: string) =>
  useAtomValue(rbacAtoms.roleDetails(id));

export const useCreateRBACRole = () =>
  useAppMutation({
    mutationFn: async (params: { description?: string; name: string }) => {
      try {
        const { data } = await fetchApiClient.rbac.createRbacRole(params);
        return data;
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });

export const useUpdateRBACRole = () =>
  useAppMutation({
    mutationFn: async (params: {
      id: string;
      data: {
        description?: string;
        name?: string;
      };
    }) => {
      try {
        await fetchApiClient.rbac.updateRbacRole(+params.id, params.data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: rbacQueries.roleDetails(+id).queryKey,
        });
        rbacAtoms.roleDetails.remove(id);
      }
    },
  });

export const useUpsertRolePermissions = () =>
  useAppMutation({
    mutationFn: async (params: {
      roleId: string;
      permissions?: {
        id: number;
      }[];
    }) => {
      try {
        await fetchApiClient.rbac.upsertRbacRolePermission(+params.roleId, {
          permissions: params.permissions,
        });
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { roleId }) => {
      if (roleId) {
        queryClient.removeQueries({
          queryKey: rbacQueries.roleDetails(+roleId).queryKey,
        });
        rbacAtoms.roleDetails.remove(roleId);
      }
    },
  });

export const useDeleteRBACRole = () =>
  useAppMutation({
    mutationFn: async (id: string) => {
      try {
        await fetchApiClient.rbac.deleteRbacRole(+id);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: rbacQueries.roles({
          page: globalStore.get(rbacAtoms.rolesFilter.currentValueAtom)?.page,
        } as { search?: string }).queryKey,
        exact: false,
      });
    },
  });

export const useRBACEndpoints = () => useAtomValue(rbacAtoms.endpoints);

export const useUpdateRBACResource = () =>
  useAppMutation({
    mutationFn: async (params: {
      id: string;
      data: {
        description?: string;
        name?: string;
      };
    }) => {
      try {
        await fetchApiClient.rbac.updateRbacResource(+params.id, params.data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { id }) => {
      if (id) {
        queryClient.removeQueries({
          queryKey: rbacQueries.resourceDetails(+id).queryKey,
        });
        rbacAtoms.resourceDetails.remove(id);
      }
    },
  });

export const useCreateRBACResource = () =>
  useAppMutation({
    mutationFn: async (data: {
      description?: string;
      endpoint: string;
      name: string;
    }) => {
      try {
        await fetchApiClient.rbac.createRbacResource(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });

export const useDeleteRBACResource = () =>
  useAppMutation({
    mutationFn: async (id: string) => {
      try {
        await fetchApiClient.rbac.deleteRbacResource(+id);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: rbacQueries.resources({
          page: globalStore.get(rbacAtoms.resourcesFilter.currentValueAtom)
            ?.page,
        } as { search?: string }).queryKey,
        exact: false,
      });
    },
  });

export const useInfiniteRBACResource = () => {
  return useInfiniteQuery(
    infiniteQueryOptions({
      queryKey: ["infinite", "rbac", "resources"],
      queryFn: ({ pageParam }) => {
        return fetchApiClient.rbac.listRbacResources({ page: pageParam } as {
          search?: string;
        });
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        return lastPage.data?.next ? lastPageParam + 1 : null;
      },
      placeholderData: keepPreviousData,
    }),
  );
};

export const useInfiniteRBACPermissions = () => {
  return useInfiniteQuery(
    infiniteQueryOptions({
      queryKey: ["infinite", "rbac", "permissions"],
      queryFn: ({ pageParam }) => {
        return fetchApiClient.rbac.listRbacPermissions({ page: pageParam } as {
          search?: string;
        });
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        return lastPage.data?.next ? lastPageParam + 1 : null;
      },
      placeholderData: keepPreviousData,
    }),
  );
};
