import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { Button, Checkbox, Divider, Input, Radio, Typography } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { QUESTION_TYPE } from "@/lib/constants";

import { useAnswerSurvey } from "../../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/surveys/answers/$id/")({
  component: () => <SurveyDetailsPage />,
  beforeLoad: () => {
    return {
      title: t("menu.details", { ns: "dashboard" }),
    };
  },
});

function SurveyDetailsPage() {
  const { id } = useParams({ from: "/_auth/_dashboard/surveys/answers/$id/" });
  const { data } = useAnswerSurvey(id);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation("dashboard");
  const { language } = i18n;

  const handleOnClose = () => {
    void navigate({ to: "/surveys/answers" });
  };

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Typography.Title level={4}>
              {language === "vi"
                ? data?.survey_data.title
                : data?.survey_data.en_title}
            </Typography.Title>
          </div>
          <div>
            <Typography.Text type="secondary">
              {data?.user && (
                <>
                  <div>
                    <strong>{t("menu.name")}:</strong> {data.user.first_name}{" "}
                    {data.user.last_name}
                  </div>
                  <div>
                    <strong>{t("menu.email")}:</strong> {data.user.email}
                  </div>
                  <div>
                    <strong>{t("menu.created_at")}:</strong>{" "}
                    {dayjs(data.created_at).format("DD/MM/YYYY HH:mm:ss")}
                  </div>
                </>
              )}
            </Typography.Text>
          </div>
        </div>
        <Divider />
        {data?.survey_data.questions?.map((question, index: number) => (
          <div key={index} className="space-y-2 my-6">
            <Typography.Text strong>
              {index + 1}.{" "}
              {language === "vi" ? question.title : question.en_title}
            </Typography.Text>

            {question.question_type === "CHOICES" ? (
              <div className="pl-4">
                {question.options?.map((option, choiceIndex: number) => (
                  <div key={choiceIndex} className="flex items-center gap-2">
                    {(question.max_choices ?? 1) > 1 ? (
                      <Checkbox
                        disabled
                        checked={question.answer?.includes(option.value ?? "")}
                      />
                    ) : (
                      <Radio
                        disabled
                        checked={question.answer?.[0] === option.value}
                      />
                    )}
                    <Typography.Text>{option.label}</Typography.Text>
                  </div>
                ))}
              </div>
            ) : (
              question.question_type === QUESTION_TYPE.TEXT && (
                <Input.TextArea
                  value={question.answer?.[0]}
                  disabled
                  className="mt-2"
                />
              )
            )}
          </div>
        ))}

        <div className="flex justify-end">
          <Button
            onClick={handleOnClose}
            className="border-brand bg-brand w-[114px]"
            type="primary"
            size="large"
          >
            {t("menu.close")}
          </Button>
        </div>
      </div>
    </MainDashboard>
  );
}
