import { createFileRoute } from "@tanstack/react-router";
import { Table, Select, Input, Form, Button, Divider, Modal } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { formatBytes, formatSeconds } from "@/lib/utils";
import { fetchApi } from "@/lib/utils/fetchUtils";

import { useSystemCache, useSystemCacheFilter } from "./-data/hooks";
import { CacheFilterParams, CacheItem, systemCacheLoader } from "./-data/store";

const REDIS_CACHE_ITEM_TYPES = [
  {
    label: t("menu.config.mobile.redisAll", { ns: "dashboard" }),
    value: "",
  },
  {
    label: t("menu.config.mobile.redisString", { ns: "dashboard" }),
    value: "string",
  },
  {
    label: "Hash",
    value: "hash",
  },
  {
    label: t("menu.config.mobile.redisList", { ns: "dashboard" }),
    value: "list",
  },
  {
    label: "Set",
    value: "set",
  },
  {
    label: "ZSet",
    value: "zset",
  },
];

const Page = () => {
  const { t } = useTranslation("dashboard");
  const { data, isLoading, refetch } = useSystemCache();
  const { setSystemCacheFilter, systemCacheFilter } = useSystemCacheFilter();
  const [viewedKey, setViewedKey] = useState<string | null>(null);
  const [viewedItem, setViewedItem] = useState<CacheItemDetail | null>(null);

  const onSearch = (value: CacheFilterParams) => {
    setSystemCacheFilter(value);
  };
  const { handlePaginateChange } = useCreateHandleFilterFunc(
    setSystemCacheFilter as never,
  );

  const COLUMNS = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      width: "50%",
    },
    {
      title: t("menu.OASIS.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: "TTL",
      dataIndex: "ttl",
      key: "ttl",
      render: (value: number) => {
        return value < 0
          ? t("menu.config.mobile.noLimits")
          : formatSeconds(value);
      },
    },
    {
      title: t("menu.config.mobile.size"),
      dataIndex: "size",
      key: "size",
      render: (value: number) => formatBytes(value),
    },
    {
      title: t("menu.action"),
      key: "action",
      render: (_: unknown, item: CacheItem) => {
        const onDelete = () => {
          const confirmTitle = t("menu.config.mobile.deleteConfirmTitle", "");
          const yes = confirm(confirmTitle.concat(item.key));
          if (yes) {
            void fetchApi
              .delete("storages/cache/detail/", {
                searchParams: { key: item.key },
              })
              .then(() => {
                void refetch();
              });
          }
        };

        const onView = () => {
          setViewedKey(item.key);
        };

        return (
          <>
            <Button type="link" onClick={onView}>
              {t("menu.view")}
            </Button>
            <Button type="link" onClick={onDelete}>
              {t("menu.button.delete")}
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!viewedKey) {
      return;
    }
    void fetchApi
      .get<{
        data: CacheItemDetail;
      }>("storages/cache/detail/", { searchParams: { key: viewedKey } })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setViewedItem(data.data);
          })
          .catch((e: unknown) => {
            console.error(e);
          });
      });
  }, [setViewedItem, viewedKey]);

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <Form layout="inline" onFinish={onSearch}>
          <Form.Item name="pattern" label={t("menu.config.mobile.pattern")}>
            <Input type="text" placeholder="*" defaultValue="*" />
          </Form.Item>
          <Form.Item name="type" label={t("menu.OASIS.type")}>
            <Select
              options={REDIS_CACHE_ITEM_TYPES}
              defaultValue=""
              style={{ width: 100 }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("menu.search.search")}
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <Table
          pagination={{
            position: ["bottomCenter"],
            total: data.data.count,
            showSizeChanger: false,
            current: systemCacheFilter.page,
            onChange: handlePaginateChange,
          }}
          columns={COLUMNS}
          dataSource={data.data.results}
          loading={isLoading}
        />
      </div>

      <Modal
        open={!!viewedItem}
        title={viewedKey}
        onCancel={() => {
          setViewedItem(null);
          setViewedKey(null);
        }}
      >
        {viewedItem ? (
          <pre>{JSON.stringify(viewedItem.value, null, 2)}</pre>
        ) : (
          <p>{t("menu.config.mobile.emergencyContact.noData")}</p>
        )}
      </Modal>
    </MainDashboard>
  );
};

export const Route = createFileRoute("/_auth/_dashboard/_system/cache/")({
  component: Page,
  loader: systemCacheLoader,
  beforeLoad: () => {
    return {
      title: t("menu.config.mobile.cache", { ns: "dashboard" }),
    };
  },
});

interface CacheItemDetail extends CacheItem {
  value: string;
}
