import {
  queryOptions,
  UndefinedInitialDataOptions,
} from "@tanstack/react-query";

export const serializeToStrings = <T>(
  record?: Record<string, string | number | T>,
) => {
  const result: Record<string, string | T> = {};
  for (const key in record) {
    result[key] =
      typeof record[key] === "object" ? record[key] : String(record[key]);
  }
  return result;
};

type TanstackQueryConfig<T> = Omit<
  UndefinedInitialDataOptions<T | undefined>,
  "queryKey" | "queryFn"
>;

export const createQuery = (index: string) => {
  return <T, K>(
    apiMethod: (args: K) => Promise<{ data?: T }>,
    tanstackQueryConfig?:
      | ((params: K) => TanstackQueryConfig<T>)
      | TanstackQueryConfig<T>,
  ) => {
    return (params: K = {} as K) => {
      const config =
        typeof tanstackQueryConfig === "function"
          ? tanstackQueryConfig(params)
          : tanstackQueryConfig;
      return queryOptions({
        queryKey: [
          index,
          apiMethod.name,
          typeof params === "object"
            ? serializeToStrings(params as Record<string, unknown>)
            : params,
        ],
        queryFn: async () => {
          const { data } = await apiMethod(params);
          return data;
        },
        ...config,
      });
    };
  };
};
