import { useNavigate } from "@tanstack/react-router";
import { Form, Typography, Input, Select } from "antd";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LayoutDetailsForm, LayoutDetailsFormProps } from "@/components";
import { filterSelectOptions } from "@/lib/utils";
import { Resource } from "@/types/Api";

import { useRBACEndpoints } from "../-data/hooks";

interface ResourceFormProps extends LayoutDetailsFormProps<Resource> {}

const ResourceForm = ({
  form,
  isEdit,
  isSubmitLoading,
  title,
  onEdit,
  onSubmit,
}: ResourceFormProps) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const handleClose = () => {
    void navigate({ to: "/rbac/resources" });
  };
  const { data } = useRBACEndpoints();
  const { data: endpoints = [] } =
    (data as { data?: string[] } | undefined) ?? {};

  return (
    <LayoutDetailsForm
      form={form}
      onClose={handleClose}
      isSubmitLoading={isSubmitLoading}
      title={title}
      onEdit={onEdit}
      onSubmit={onSubmit}
      isEdit={isEdit}
    >
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.name")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <Input
                {...field}
                disabled={!isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="description"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enDescription")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              className="col-span-2"
            >
              <Input.TextArea
                {...field}
                rows={3}
                value={field.value ?? ""}
                disabled={!isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="endpoint"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                // eslint-disable-next-line i18next/no-literal-string
                <Typography.Text className="font-medium">
                  Endpoint
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <Select
                {...field}
                value={field.value || null}
                onClear={() => {
                  form.reset((values) => {
                    return {
                      ...values,
                      endpoint: "",
                    };
                  });
                }}
                showArrow
                showSearch
                allowClear
                maxTagTextLength={24}
                options={endpoints.map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })}
                filterOption={filterSelectOptions}
                placeholder={t("menu.select", { what: "endpoint" })}
                disabled={!isEdit}
              />
            </Form.Item>
          );
        }}
      />
    </LayoutDetailsForm>
  );
};

export default ResourceForm;
