import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { Permission } from "@/types/Api";

import PermissionsForm from "../../../-components/PermissionsForm";
import { useRBACPermissionDetails } from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/rbac/permissions/_details/$id/",
)({
  component: () => <PermissionsDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function PermissionsDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/rbac/permissions/_details/$id/",
  });
  const { data } = useRBACPermissionDetails(id);

  const form = useForm<Omit<Permission, "resource"> & { resource: number }>({
    defaultValues: {
      name: data?.name,
      resource: data?.resource.id,
      action: data?.action,
    },
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  return (
    <MainDashboard>
      <PermissionsForm
        onEdit={() =>
          void navigate({ to: "/rbac/permissions/$id/edit", params: { id } })
        }
        onClose={() => void navigate({ to: "/rbac/permissions" })}
        title={t("menu.details")}
        form={form}
      />
    </MainDashboard>
  );
}
