import { createFileRoute, Link } from "@tanstack/react-router";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { calculateTableRecordByIndex, formatFullDateTime } from "@/lib/utils";

import SurveyAnswerHeader from "../-components/SurveyAnswerHeader";
import { useSurveyAnswers, useSurveyAnswersFilter } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/surveys/answers/")({
  component: () => <SurveyAnswerPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function SurveyAnswerPage() {
  const { t } = useTranslation("dashboard");
  const { data, isFetching } = useSurveyAnswers();
  const { filter, setFilter } = useSurveyAnswersFilter();
  const onRow = useOnRowTable({ to: "/surveys/answers/$id" });
  const { handlePaginateChange } = useCreateHandleFilterFunc(setFilter);
  const dataSource = useDataSource(data?.results);

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <SurveyAnswerHeader
          search={filter?.search}
          title={t("menu.list")}
          isListLoading={isFetching}
        />
        <Table
          loading={isFetching}
          onRow={onRow}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "id",
              width: "1ch",
              render: (_, record, index) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/surveys/answers/$id"
                    params={{ id: record.id.toString() }}
                  >
                    {calculateTableRecordByIndex(index, filter?.page)}
                  </Link>
                );
              },
            },
            {
              title: t("menu.surveys.answerUser"),
              key: "answer_user",
              render: (_, record) => {
                return <span>{record.user.email}</span>;
              },
            },
            {
              title: t("menu.surveys.enSurveyTitle"),
              key: "survey_name_en",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/surveys/$id"
                    params={{ id: record.survey_data.id?.toString() ?? "" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <span>{record.survey_data.en_title ?? "--/--"}</span>
                  </Link>
                );
              },
            },
            {
              title: t("menu.surveys.vnSurveyTitle"),
              key: "survey_name",
              render: (_, record) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/surveys/$id"
                    params={{ id: record.survey_data.id?.toString() ?? "" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <span>{record.survey_data.title ?? "--/--"}</span>
                  </Link>
                );
              },
            },
            {
              title: t("menu.news.createdAt"),
              key: "created_at",
              render: (_, record) => {
                return <span>{formatFullDateTime(record.created_at)}</span>;
              },
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
