import {
  infiniteQueryOptions,
  keepPreviousData,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";

import { useAppMutation } from "@/hooks/useAppMutation";
import { queryClient } from "@/lib/config/queryClient";
import { COUNSELLING_SURVEY_ID } from "@/lib/constants";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { SurveyFormCreate, SurveyFormUpdate } from "@/types/Api";

import { surveyAtoms, surveyQueries } from "./store";

export const useSurveysFilter = () => {
  const filter = useAtomValue(surveyAtoms.listFilter.currentValueAtom);
  const setFilter = useSetAtom(surveyAtoms.listFilter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useSurveys = () => {
  return useAtomValue(surveyAtoms.list);
};

export const useSurveyAnswersFilter = () => {
  const filter = useAtomValue(surveyAtoms.listAnswerFilter.currentValueAtom);
  const setFilter = useSetAtom(surveyAtoms.listAnswerFilter.debouncedValueAtom);
  return { filter, setFilter };
};

export const useSurveyAnswers = () => {
  return useAtomValue(surveyAtoms.listAnswer);
};

export const useInfiniteSurveys = () => {
  return useInfiniteQuery(
    infiniteQueryOptions({
      queryKey: ["infinite", "surveys", "list"],
      queryFn: ({ pageParam }) => {
        return fetchApiClient.surveys.getListSurveyFormWithPagination({
          page: pageParam,
        } as {
          search?: string;
        });
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        return lastPage.data?.next ? lastPageParam + 1 : null;
      },
      placeholderData: keepPreviousData,
    }),
  );
};

export const useSurvey = (id: string) => {
  return useAtomValue(surveyAtoms.details(id));
};

export const useAnswerSurvey = (id: string) => {
  return useAtomValue(surveyAtoms.answerDetails(id));
};

export const useCreateSurvey = () =>
  useMutation({
    mutationFn: async (data: SurveyFormCreate) => {
      try {
        await fetchApiClient.surveys.createASurveyForm(data);
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
  });

export const useUpdateSurvey = () => {
  return useAppMutation({
    mutationFn: async (data: SurveyFormUpdate & { surveyId?: string }) => {
      try {
        await fetchApiClient.surveys.updateSurveyForm(
          data.surveyId ? data.surveyId : COUNSELLING_SURVEY_ID,
          data,
        );
      } catch (error) {
        throw new Error("Something went wrong!");
      }
    },
    onSuccess: (_, { surveyId }) => {
      if (surveyId) {
        queryClient.removeQueries({
          queryKey: surveyQueries.details(surveyId).queryKey,
        });
        surveyAtoms.details.remove(surveyId);
      }
    },
  });
};
