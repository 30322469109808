import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { QUESTION_TYPE } from "@/lib/constants";
import { checkQuestionTypeIsChoice, mappingContentToBase64 } from "@/lib/utils";
import { ISurveyForm } from "@/types";
import { SurveyForm as SurveyFormType } from "@/types/Api";

import SurveyForm from "../../../-components/SurveyForm";
import { counsellingSchema } from "../../../-data";
import { useSurvey, useUpdateSurvey } from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/surveys/_list/_details/$id/edit",
)({
  component: () => <SurveyDetailsPage />,
  beforeLoad: () => {
    return {
      title: t("menu.edit", { ns: "dashboard" }),
    };
  },
});

function SurveyDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/surveys/_list/_details/$id/edit",
  });
  const { data } = useSurvey(id);
  const { resolver } = useYupResolver<ISurveyForm>(counsellingSchema);
  const form = useForm<ISurveyForm>({
    defaultValues: {
      ...data,
      questions: data?.questions?.map((item) => {
        return {
          ...item,
          question_type:
            item.question_type === QUESTION_TYPE.CHOICES
              ? (item.max_choices ?? 0) > 1
                ? "CHECKBOXES"
                : "MULTIPLE_CHOICE"
              : item.question_type,
        };
      }),
    },
    resolver,
  });

  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const { mutate, isPending } = useUpdateSurvey();

  const handleOnClose = () => {
    void navigate({ to: "/surveys/$id", params: { id } });
  };

  const handleSubmit = (data: ISurveyForm) => {
    const mutateData = {
      ...data,
      questions: data.questions?.map((item, index) => {
        const isChoice = checkQuestionTypeIsChoice(item.question_type);
        return {
          ...item,
          order: index + 1,
          question_type: isChoice
            ? (QUESTION_TYPE.CHOICES as NonNullable<
                SurveyFormType["questions"]
              >[number]["question_type"])
            : (item.question_type as NonNullable<
                SurveyFormType["questions"]
              >[number]["question_type"]),
          options: item.options?.map((item) => ({
            ...item,
            label: item.label ?? "",
            en_label: item.en_label ?? "",
            value: item.value ?? "",
          })),
        };
      }),
    };
    mutate(
      mappingContentToBase64(mutateData, "en_description", "description"),
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.update.success", {
              what: t("menu.counsellingService.survey").toLowerCase(),
            }),
          });
          void navigate({
            to: "/surveys/$id",
            params: {
              id,
            },
          });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.update.error", {
              what: t("menu.counsellingService.survey").toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <SurveyForm
        title={t("menu.surveys.survey")}
        form={form}
        isEdit
        onSubmit={handleSubmit}
        onClose={handleOnClose}
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
}
