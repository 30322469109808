import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { Avatar, Button, Dropdown, Layout, Typography } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { t } from "i18next";
import { useEffect, useState } from "react";

import { PendingComponent, Stack } from "@/components";
import { authAtom, useAuth } from "@/jotai-atoms/oidc";
import { userQueries } from "@/jotai-atoms/user";
import { useUserInfo } from "@/jotai-atoms/user";
import { APP_MODE, AppMode } from "@/lib/constants";
import { isStaff, cn } from "@/lib/utils";

import ChangeLanguage from "./-components/ChangeLanguage";
import { Sidebar } from "./-components/Sidebar";

const { Content, Header } = Layout;

const ErrorComponent = () => {
  const auth = useAuth();
  if (import.meta.env.DEV) {
    console.log("error component");
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      void auth.signoutRedirect();
    }, 1250);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [auth]);
  return <PendingComponent />;
};

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ context: { queryClient, globalStore } }) => {
    // Auth guard
    const auth = globalStore.get(authAtom);
    try {
      const [user, userInfo] = await Promise.all([
        auth.getUser(),
        queryClient.ensureQueryData(userQueries.userInfo()),
      ]);
      if (!user || !userInfo || !isStaff(userInfo)) {
        if (userInfo && !isStaff(userInfo)) {
          if (import.meta.env.DEV) {
            console.log({ userInfo });
          }
          notification.error({
            message: t("menu.login.notAuthorize", { ns: "dashboard" }),
            description: t("menu.login.contactAdmin", { ns: "dashboard" }),
          });
        }
        await new Promise((res) => {
          setTimeout(() => {
            res(100);
          }, 1250);
        });
        // return auth.signoutRedirect();
      }
    } catch (error) {
      console.log(error);
    }
  },
  pendingMinMs: 0,
  pendingMs: 0,
  pendingComponent: () => <PendingComponent />,
  component: () => <Auth />,
  errorComponent: () => <ErrorComponent />,
});

function Auth() {
  const [collapsed, setCollapsed] = useState(false);
  const userInfo = useUserInfo();
  const navigate = useNavigate();

  const dropdownItems: unknown[] = [
    {
      label: "Logout",
      key: 4,
      onClick: () => {
        void navigate({ to: "/auth/logout" });
      },
    },
  ];

  if (APP_MODE === AppMode.DEV) {
    dropdownItems.push(
      ...[
        { label: "My Account", key: 1 },
        { type: "divider" },
        { label: "Settings", key: 2 },
        { label: "Support", key: 3 },
        { type: "divider" },
      ],
    );
  }

  return (
    <Layout hasSider className="min-h-screen">
      <Sidebar collapsed={collapsed} setCollapse={setCollapsed} />
      <Layout
        className={cn(
          "site-layout relative transition-all",
          collapsed ? "ml-[80px]" : "ml-[200px]",
        )}
      >
        <Header
          className={cn(
            "border-b-solid sticky top-0 z-10 flex w-full items-center border-x-0 border-b border-t-0 border-solid border-b-[#F0F0F0] bg-white p-4",
          )}
        >
          <Button
            type="text"
            icon={
              collapsed ? (
                <MenuUnfoldOutlined className="text-xl" />
              ) : (
                <MenuFoldOutlined className="text-xl" />
              )
            }
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
          <ChangeLanguage />
          <Dropdown
            menu={{
              items: dropdownItems as unknown as ItemType[],
            }}
          >
            <Stack className="cursor-pointer gap-2">
              <Avatar>{userInfo.data?.user.email?.[0]}</Avatar>
              <Typography>{userInfo.data?.user.email}</Typography>
            </Stack>
          </Dropdown>
        </Header>
        <Content className="z-0 flex flex-1 flex-col">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
