import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { Role } from "@/types/Api";

import RoleForm from "../../../-components/RoleForm";
import { rbacRoleSchema } from "../../../-data";
import { useRBACRoleDetails } from "../../../-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/rbac/roles/_details/$id/",
)({
  component: () => <RoleDetailsPage />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function RoleDetailsPage() {
  const { id } = useParams({
    from: "/_auth/_dashboard/rbac/roles/_details/$id/",
  });
  const { data } = useRBACRoleDetails(id);
  const { resolver } = useYupResolver<Role & { permissions?: number[] }>(
    rbacRoleSchema,
  );
  const form = useForm<Role & { permissions?: number[] }>({
    defaultValues: {
      ...data,
      permissions: data?.permissions?.map((p) => p.id) ?? [],
    },
    resolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  return (
    <MainDashboard>
      <RoleForm
        onEdit={() =>
          void navigate({ to: "/rbac/roles/$id/edit", params: { id } })
        }
        onClose={() => void navigate({ to: "/rbac/roles" })}
        title={t("menu.details")}
        form={form}
      />
    </MainDashboard>
  );
}
