import { Input, Form, Typography, Select } from "antd";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LayoutDetailsForm, LayoutDetailsFormProps } from "@/components";
import { filterSelectOptions } from "@/lib/utils";
import { Role } from "@/types/Api";

import { useInfiniteRBACPermissions } from "../-data/hooks";

interface Props
  extends LayoutDetailsFormProps<Role & { permissions?: number[] }> {}

const RoleForm = (props: Props) => {
  const { form } = props;
  const { t } = useTranslation("dashboard");
  const query = useInfiniteRBACPermissions();

  const options: { label: string; value: number | undefined }[] =
    query.data?.pages
      .flatMap((item) =>
        item.data?.results?.flatMap((item) => {
          if (item.resource.name && item.id !== undefined) {
            return {
              label: `${item.action} - ${item.resource.name}`,
              value: item.id,
            };
          }
          return undefined;
        }),
      )
      .filter(
        (item): item is { label: string; value: number } => item !== undefined,
      ) ?? [];

  return (
    <LayoutDetailsForm {...props}>
      <Controller
        control={form.control}
        name="name"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.name")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <Input
                {...field}
                disabled={!props.isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="description"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enDescription")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              className="col-span-2"
            >
              <Input.TextArea
                {...field}
                rows={3}
                value={field.value ?? ""}
                disabled={!props.isEdit}
                placeholder={t("menu.editHere")}
              />
            </Form.Item>
          );
        }}
      />

      <Controller
        control={form.control}
        name="permissions"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.rbac.permission")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
              className="col-span-2"
            >
              <Select
                {...field}
                mode="multiple"
                value={field.value ?? null}
                onClear={() => {
                  form.reset((values) => {
                    return {
                      ...values,
                      permissions: [],
                    };
                  });
                }}
                loading={query.isFetching}
                showArrow
                showSearch
                allowClear
                maxTagTextLength={24}
                options={options}
                onPopupScroll={(e) => {
                  const { scrollTop, offsetHeight, scrollHeight } =
                    e.target as HTMLDivElement;
                  if (
                    query.hasNextPage &&
                    !query.isFetching &&
                    scrollTop + offsetHeight === scrollHeight
                  ) {
                    void query.fetchNextPage();
                  }
                }}
                filterOption={filterSelectOptions}
                placeholder={t("menu.select", {
                  what: t("menu.rbac.permission").toLowerCase(),
                })}
                disabled={!props.isEdit}
              />
            </Form.Item>
          );
        }}
      />
      {!props.isEdit && (
        <Controller
          control={form.control}
          name="code"
          render={({ field, fieldState: { error } }) => {
            return (
              <Form.Item
                label={
                  // eslint-disable-next-line i18next/no-literal-string
                  <Typography.Text className="font-medium">
                    Code
                  </Typography.Text>
                }
                validateStatus={error ? "error" : ""}
                help={error?.message}
                required
                className="col-span-2"
              >
                <Input
                  {...field}
                  readOnly
                  disabled={!props.isEdit}
                  placeholder={t("menu.editHere")}
                />
              </Form.Item>
            );
          }}
        />
      )}
    </LayoutDetailsForm>
  );
};

export default RoleForm;
