import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { Resource } from "@/types/Api";

import ResourceForm from "../-components/ResourceForm";
import { rbacResourceSchema } from "../-data";
import { useCreateRBACResource } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/rbac/resources/add")({
  component: () => <AddResourcePage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

function AddResourcePage() {
  const { resolver } = useYupResolver<Resource>(rbacResourceSchema);
  const form = useForm<Resource>({
    defaultValues: {
      endpoint: "",
      name: "",
      description: "",
    },
    resolver,
  });
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateRBACResource();

  const handleSubmit = (params: Resource) => {
    mutate(params, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.create.success", {
            what: t("menu.rbac.resource").toLowerCase(),
          }),
        });
        void navigate({ to: "/rbac/resources" });
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.create.error", {
            what: t("menu.rbac.resource").toLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <ResourceForm
        onClose={() => void navigate({ to: "/rbac/resources" })}
        title={t("menu.details")}
        form={form}
        onSubmit={handleSubmit}
        isEdit
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
}
