import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Button, Modal, notification, Table } from "antd";
import { useTranslation } from "react-i18next";

import { ListHeader, MainDashboard } from "@/components";
import { useCreateHandleFilterFunc } from "@/hooks/useCreateHandleFilterFunc";
import { useDataSource } from "@/hooks/useDataSource";
import { useOnRowTable } from "@/hooks/useOnRowTable";
import { calculateTableRecordByIndex, formatEndpoint } from "@/lib/utils";

import {
  useDeleteRBACPermissions,
  useRBACPermissions,
  useRBACPermissionsFilter,
} from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/rbac/permissions/")({
  component: () => <RBACPermissions />,
  beforeLoad: () => {
    return {
      title: null,
    };
  },
});

function RBACPermissions() {
  const { t } = useTranslation("dashboard");
  const onRow = useOnRowTable({ to: "/rbac/permissions/$id" });
  const { data, isFetching } = useRBACPermissions();
  const { filter, setFilter } = useRBACPermissionsFilter();
  const { handlePaginateChange } = useCreateHandleFilterFunc(setFilter);
  const dataSource = useDataSource(data?.results);
  const navigate = useNavigate();
  const { mutate } = useDeleteRBACPermissions();

  return (
    <MainDashboard>
      <div className="bg-white p-6 py-4">
        <ListHeader
          title={t("menu.rbac.permission")}
          // search={filter?.search}
          // onChange={handleSearchChange}
          // isLoading={isFetching}
          onAddNewClick={() => {
            void navigate({ to: "/rbac/permissions/add" });
          }}
        />
        <Table
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            total: data?.count,
            showSizeChanger: false,
            current: filter?.page,
            onChange: handlePaginateChange,
          }}
          onRow={onRow}
          dataSource={dataSource}
          columns={[
            {
              title: "No.",
              key: "id",
              width: "1ch",
              render: (_, record, index) => {
                return (
                  <Link
                    className="text-[var(--ant-primary-color)]"
                    to="/oasis/advisor/$id"
                    params={{ id: String(record.id) }}
                  >
                    {calculateTableRecordByIndex(index, filter?.page)}
                  </Link>
                );
              },
            },
            {
              title: t("menu.action"),
              key: "action",
              render: (_, record) => record.action,
            },
            {
              title: t("menu.rbac.resourceName"),
              key: "resourceName",
              render: (_, record) => record.resource.name,
            },
            {
              title: "Endpoint",
              key: "endpoint",
              render: (_, record) => formatEndpoint(record.resource.endpoint),
            },
            {
              title: t("menu.action"),
              key: "action",
              render: (_, { id }) => (
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    Modal.confirm({
                      title: t("menu.delete.title"),
                      icon: (
                        <ExclamationCircleOutlined className="!text-red-500" />
                      ),
                      content: t("menu.delete.desc", { ns: "dashboard" }),
                      onOk() {
                        mutate(String(id), {
                          onSuccess: () => {
                            notification.success({
                              message: t("menu.success"),
                              description: t("menu.delete.success", {
                                what: t("menu.rbac.resource").toLowerCase(),
                              }),
                            });
                          },
                          onError: () => {
                            notification.error({
                              message: t("menu.error"),
                              description: t("menu.delete.error", {
                                what: t("menu.rbac.resource").toLowerCase(),
                              }),
                            });
                          },
                        });
                      },
                    });
                  }}
                  icon={<DeleteOutlined className="text-xl" />}
                  danger
                />
              ),
            },
          ]}
        />
      </div>
    </MainDashboard>
  );
}
