import { ISurveyQuestionType } from "@/types";

export const BACKEND_API_URI = import.meta.env.VITE_BACKEND_API_URI;
export const UAT_MODE = import.meta.env.VITE_APP_MODE === "uat";
export const APP_MODE = import.meta.env.VITE_APP_MODE as AppMode;

export enum AppMode {
  DEV = "dev",
  UAT = "uat",
  PROD = "prod",
}

export const PREFIX_IMG_FILE = "image/";

export const SUPPORTED_IMAGE_TYPES = [
  `${PREFIX_IMG_FILE}jpeg`,
  `${PREFIX_IMG_FILE}png`,
  `${PREFIX_IMG_FILE}jpg`,
  `${PREFIX_IMG_FILE}apng`,
];
export const LOGIN_ROUTE = "/auth/login";

export const OIDC_AUTHORITY = import.meta.env.VITE_APP_AUTH_AUTHORITY;
export const OIDC_CLIENT_ID = import.meta.env.VITE_APP_AUTH_CLIENT_ID;
export const OIDC_TENANT_ID = import.meta.env.VITE_APP_AUTH_TENTANT_ID;
export const OIDC_REDIRECT_URI: string = import.meta.env
  .VITE_APP_AUTH_REDIRECT_URI;
export const OIDC_POST_LOGOUT_REDIRECT_URI = import.meta.env
  .VITE_APP_AUTH_POST_LOGOUT_REDIRECT_URI;
export const OIDC_SILENT_REDIRECT_URI = import.meta.env
  .VITE_APP_AUTH_SILENT_REDIRECT_URI;

export const LOCAL_STORAGE_KEYS = {
  theme: "ui-theme",
  authProvider: "oidc-auth-provider",
} as const;

export const BLOB_STORAGE_URL = import.meta.env.VITE_BLOB_STORAGE_URL;
export const CDN_URL = import.meta.env.VITE_CDN_URL;
export const QUESTION_TYPE: Partial<
  Record<
    Exclude<ISurveyQuestionType, undefined>,
    Exclude<ISurveyQuestionType, undefined>
  >
> = {
  CHOICES: "CHOICES",
  TEXT: "TEXT",
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
  CHECKBOXES: "CHECKBOXES",
  DROPDOWN: "DROPDOWN",
  DATE: "DATE",
  RATE: "RATE",
} as const;

export const FEATURE_FLAG_COUNSELLING_SURVEY = APP_MODE !== AppMode.PROD;

export const COUNSELLING_SURVEY_ID =
  import.meta.env.VITE_COUNSELLING_SURVEY_ID ?? "1";
export const DATE_ONLY = "YYYY-MM-DD";
export const DATE_HOUR_MINUTES = "YYYY-MM-DD HH:mm";
