import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components";
import useYupResolver from "@/hooks/useYupResolver";
import { QUESTION_TYPE } from "@/lib/constants";
import { checkQuestionTypeIsChoice, mappingContentToBase64 } from "@/lib/utils";
import { ISurveyForm } from "@/types";
import { SurveyForm as SurveyFormType } from "@/types/Api";

import SurveyForm from "../-components/SurveyForm";
import { counsellingSchema } from "../-data";
import { useCreateSurvey } from "../-data/hooks";

export const Route = createFileRoute("/_auth/_dashboard/surveys/_list/add")({
  component: () => <AddSurveyPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
});

const AddSurveyPage = () => {
  const { resolver } = useYupResolver<ISurveyForm>(counsellingSchema);
  const form = useForm<ISurveyForm>({
    resolver,
  });

  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");
  const { mutate, isPending } = useCreateSurvey();

  const handleOnClose = () => {
    void navigate({ to: "/surveys" });
  };

  const handleSubmit = (data: ISurveyForm) => {
    const mutateData = {
      ...data,
      title: data.title ?? "",
      en_title: data.en_title ?? "",
      questions: data.questions?.map((item, index) => {
        const isChoice = checkQuestionTypeIsChoice(item.question_type);
        return {
          ...item,
          order: index + 1,
          question_type: (isChoice
            ? QUESTION_TYPE.CHOICES
            : item.question_type) as Exclude<
            NonNullable<SurveyFormType["questions"]>[number]["question_type"],
            undefined
          >,
          options: item.options?.map((item) => ({
            ...item,
            label: item.label ?? "",
            en_label: item.en_label ?? "",
            value: item.value ?? "",
          })),
          en_title: item.en_title ?? "",
          title: item.title ?? "",
        };
      }),
    };
    mutate(
      mappingContentToBase64(mutateData, "en_description", "description"),
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.create.success", {
              what: t("menu.counsellingService.survey").toLowerCase(),
            }),
          });
          void navigate({
            to: "/surveys",
          });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.create.error", {
              what: t("menu.counsellingService.survey").toLowerCase(),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <SurveyForm
        title={t("menu.surveys.survey")}
        form={form}
        isEdit
        onSubmit={handleSubmit}
        onClose={handleOnClose}
        isSubmitLoading={isPending}
      />
    </MainDashboard>
  );
};
